<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE EVALUACIÓN DE RIESGOS ANTISOBORNO
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de evaluación de riesgos antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" v-if="checkPermissions('017-37001-ERA-RIA','r') == 1" class="my-2">
                            <b-button block :to="{name: 'Riesgos antisoborno'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="ico fas fa-cog fa-3x"></i><br>Riesgos antisoborno
                            </b-button>
                        </b-col>
                        <b-col cols="6" v-if="checkPermissions('017-37001-ERA','c') == 1" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Selección riesgos antisoborno'}">
                                <i style="inline-size: auto" class="ico fas fa-plus-circle fa-3x"></i><br>Reevaluación
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Evaluación de riesgos registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaEvaluaciones" :fields="campoEvaluaciones" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <!-- <b-button v-if="checkPermissions('003-RIE-EVA','u') == 1" :to="{name: 'Selección riesgos antisoborno', params: {id: param.item.idEvaluacion}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button> -->
                                    <b-button v-if="checkPermissions('017-37001-ERA-VER','r') == 1 && param.item.idEvaluacion" :to="{name: 'Ver evaluaciones de riesgos antisoborno', params: {id: param.item.idRiesgo}}" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Ver evaluaciones'">
                                        <i class="fas fa-cog my-0 mx-0"></i>
                                    </b-button>
                                    <!-- <b-button v-if="checkPermissions('003-RIE-EVA','d') == 1" @click="eliminarEvaluacion(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button> -->
                                </template>
                                <template #cell(tipo)="param">
                                    <!-- <span v-if="param.item.datosAcordionRiesgo">{{JSON.parse(param.item.datosAcordionRiesgo).tipoTexto}}</span> -->
                                    <span v-if="param.item.tipo >= 0 && param.item.tipo <= 6"><i class="fas fa-circle text-success"></i> Bajo</span>
                                    <span v-else-if="param.item.tipo > 6 && param.item.tipo < 16"><i class="fas fa-circle text-warning"></i> Medio</span>
                                    <span v-else-if="param.item.tipo >= 16"><i class="fas fa-circle text-danger"></i> Alto</span>
                                </template>
                                <template #cell(tipoActual)="param">
                                    <!-- <span v-if="param.item.datosAcordionRiesgo">{{JSON.parse(param.item.datosAcordionRiesgo).tipoActual}}</span> -->
                                    <span v-if="param.item.datosAcordionRiesgo && JSON.parse(param.item.datosAcordionRiesgo).tipoActual >= 0 && JSON.parse(param.item.datosAcordionRiesgo).tipoActual <= 6"><i class="fas fa-circle text-success"></i> Bajo</span>
                                    <span v-else-if="param.item.datosAcordionRiesgo && JSON.parse(param.item.datosAcordionRiesgo).tipoActual > 6 && JSON.parse(param.item.datosAcordionRiesgo).tipoActual < 16"><i class="fas fa-circle text-warning"></i> Medio</span>
                                    <span v-else-if="param.item.datosAcordionRiesgo && JSON.parse(param.item.datosAcordionRiesgo).tipoActual >= 16"><i class="fas fa-circle text-danger"></i> Alto</span>
                                    <span v-else>-</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            labelEstadistica: ["Bajo", "Medio", "Alto"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoEvaluaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Riesgo",
                    class: "text-center",
                },
                {
                    key: "proceso",
                    label: "Proceso",
                    class: "text-center",
                },
                {
                    key: "puestoTrabajo",
                    label: "Puesto de trabajo",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo inicial",
                    class: "text-center",
                },
                {
                    key: "tipoActual",
                    label: "Tipo actual",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaEvaluaciones: [],
            listaEvaluaciones: [],
            datosSession: {
                idCliente: ''
            },
            datosEstadistica: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarEvaluaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-riesgos-antisoborno-sga", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {

                    const setObj = new Set(); // creamos pares de clave y array
                    const unicos = response.data.reduce((acc, item) => {
                        if (!setObj.has(item.idRiesgo)) {
                            setObj.add(item.idRiesgo, item)
                            acc.push(item)
                        }
                        return acc;
                    }, []);
                    me.listaEvaluaciones = unicos;
                    for (const i in me.listaEvaluaciones) {
                        if(me.listaEvaluaciones[i].datosAcordionRiesgo){
                            me.datosEstadistica.push({
                                tipoActual: JSON.parse(me.listaEvaluaciones[i].datosAcordionRiesgo).tipoActual
                            })
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarEvaluacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la evaluación?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-evaluacion-riesgo", {
                            idEvaluacionRiesgo: param.item.idEvaluacionRiesgo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarEvaluaciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,

                backgroundColor: [
                    'rgba(107,186,112,0.5)',
                    'rgba(248,148,6,0.5)',
                    'rgba(176,43,44,0.5)',
                ],
                data: [this.datosEstadistica.filter(x => x.tipoActual>=0 && x.tipoActual<=6).length, this.datosEstadistica.filter(x => x.tipoActual>6 && x.tipoActual<16).length, this.datosEstadistica.filter(x => x.tipoActual>=16).length]
            }]
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarEvaluaciones();
        }
    }

}
</script>
